// components/Canvas.js
import React, { useState, useRef } from "react";
import Card from "./Card";

const Canvas = () => {
  const [cards, setCards] = useState([
    { id: 1, text: "火", x: 100, y: 100 },
    { id: 2, text: "水", x: 150, y: 150 },
    // 可能的其他卡片初始化...
  ]);

  const [deck, setDeck] = useState(["金", "木", "水", "火", "土"]);

  const addCardToCanvas = (cardText) => {
    const newCard = {
      id: Math.max(0, ...cards.map((c) => c.id)) + 1, // 简单的ID生成逻辑
      text: cardText,
      x: 200, // 初始化位置，可根据需要调整
      y: 400, // 初始化位置，可根据需要调整
    };
    setCards((prevCards) => [...prevCards, newCard]);
  };

  // 合成卡片并更新状态
  const combineCards = (card1Id, card2Id, combinedText, count) => {
    setCards((prevCards) => {
      // 移除原来的两张卡片
      const filteredCards = prevCards.filter(
        (card) => card.id !== card1Id && card.id !== card2Id
      );

      // 添加新的合成卡片
      const newCard = {
        id: Math.max(...prevCards.map((card) => card.id)) + 1, // 简单生成新ID
        text: combinedText,
        x:
          (prevCards.find((card) => card.id === card1Id).x +
            prevCards.find((card) => card.id === card2Id).x) /
          2,
        y:
          (prevCards.find((card) => card.id === card1Id).y +
            prevCards.find((card) => card.id === card2Id).y) /
          2,
        count: count
      };

      setDeck((prevDeck) => Array.from(new Set([combinedText, ...prevDeck])));

      return [...filteredCards, newCard];
    });
  };

  const handleCombineRequest = (card1Id, card2Id) => {
    const card1Text = cards.find((card) => card.id === card1Id).text;
    const card2Text = cards.find((card) => card.id === card2Id).text;
    setCards((prevCards) =>
      prevCards.map((card) => {
        return card.id === card1Id || card.id === card2Id
          ? { ...card, isProcessing: true }
          : card;
      })
    );
    fetch("https://alchemy.cc4ai.com/api/combine", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ card1: card1Text, card2: card2Text }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.error) {
          console.error("Error combining cards:", data.error);
          setCards((prevCards) =>
            prevCards.map((card) => {
              return card.id === card1Id || card.id === card2Id
                ? { ...card, isProcessing: false }
                : card;
            })
          );
        } else {
          combineCards(card1Id, card2Id, data.combinedValue, data.count);
        }
      })
      .catch((error) => {
        console.error("Error combining cards:", error);
      });
  };

  const cref = useRef();

  const moveCard = (id, x, y) => {
    const newCards = cards.map((card) => {
      if (card.id === id) {
        return { ...card, x, y };
      }
      return card;
    });
    setCards(newCards);
  };

  function checkOverlap(cardA, cardB) {
    const threshold = 0.35; // 重叠阈值
    const cardWidth = 75;
    const cardHeight = 100;
    const cardArea = cardWidth * cardHeight;

    // 计算重叠区域的面积
    const xOverlap = Math.max(
      0,
      Math.min(cardA.x + cardWidth, cardB.x + cardWidth) -
        Math.max(cardA.x, cardB.x)
    );
    const yOverlap = Math.max(
      0,
      Math.min(cardA.y + cardHeight, cardB.y + cardHeight) -
        Math.max(cardA.y, cardB.y)
    );
    const overlapArea = xOverlap * yOverlap;

    // 检查是否达到重叠阈值
    return overlapArea / cardArea >= threshold;
  }

  const handleDragEnd = (id, x, y) => {
    // 更新卡片位置，这里可能需要根据你的布局进行适当调整
    // 例如，根据父容器的位置调整卡片的相对位置
    moveCard(id, x, y);
    // 然后检查重叠
    cards.forEach((card) => {
      if (card.id !== id) {
        if (checkOverlap(card, { id, x, y })) {
          console.log(`Card ${id} overlaps with card ${card.id}`);
          handleCombineRequest(id, card.id);
        }
      }
    });
  };

  return (
    <div>
      <div
        style={{ height: "80vh", position: "relative", overflow: "hidden" }}
        ref={cref}
      >
        {/* 渲染画布中的卡片 */}
        {cards.map((card) => (
          <Card
            key={card.id}
            id={card.id}
            count={card.count}
            text={card.text}
            onMove={moveCard}
            onDragEnd={handleDragEnd}
            initialX={card.x}
            initialY={card.y}
            constraintRef={cref}
            isProcessing={card.isProcessing}
          />
        ))}
      </div>
      <div className="card-deck">
        {/* 渲染卡牌选项 */}
        {deck.map((cardText, index) => (
          <div
            key={index}
            className="card-option"
            onClick={() => addCardToCanvas(cardText)}
          >
            {cardText}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Canvas;

// App.js
import React from 'react';
import Canvas from './Canvas';

function App() {
  return (
      <div className="App">
        <Canvas />
      </div>
  );
}

export default App;

// components/Card.js
import React, { useEffect, useState } from "react";
import { motion, useMotionValue } from "framer-motion";

async function generateImage(cardText) {
  try {
    const response = await fetch('https://alchemy.cc4ai.com/api/generate-image?cardText='+cardText, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    return data.imageUrl;
  } catch (error) {
    console.error('Error generating image:', error);
    return null; // 或返回默认背景图像的路径
  }
}

const Card = ({
  id,
  text,
  count,
  onMove,
  onDragEnd,
  constraintRef,
  initialX,
  initialY,
  isProcessing,
}) => {
  const x = useMotionValue(initialX);
  const y = useMotionValue(initialY);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleDragEnd = (event, info) => {
    onDragEnd(id, x.get(), y.get());
  };

  useEffect(() => {
    const fetchImage = async () => {
      console.log('ff', text);
      setIsLoading(true);
      const imageUrl = await generateImage(text);
      if (imageUrl) {
        setBackgroundImage(imageUrl);
      } else {
        // 图像生成失败，使用默认背景
        setBackgroundImage(null);
      }
      setIsLoading(false);
    };
    fetchImage();
  }, [text]);

  const className =
    "card " + (isProcessing ? "shining " : "") + (isLoading ? "shimmer " : "");

  return (
    <motion.div
      layout
      drag={!isProcessing}
      dragMomentum={false}
      className={className}
      onDrag={(event, info) => onMove(id, info.point.x, info.point.y)}
      onDragEnd={handleDragEnd}
      dragConstraints={constraintRef}
      style={{
        width: "75px", // 设置宽度
        height: "100px", // 设置高度
        backgroundImage: isLoading ? "none" : `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundColor: isLoading ? "#f0f0f0" : "transparent",
        x,
        y,
      }}
    >
      <span className="card-text" data-text={text}>{text}</span>
      <span className="count-text">
        {count ? (count > 0 ? `已被发现${count}次` : `发现新公式！`) : ""}
      </span>
    </motion.div>
  );
};

export default Card;
